<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="500"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="话题名称"
          prop="topicTitle"
          v-bind="formItemLayout"
      >
        <a-input
            v-model="form.topicTitle"
            allow-clear
            placeholder="输入话题名称"
        />
      </a-form-model-item>

      <a-form-model-item label="话题描述" v-bind="formItemLayout">
        <a-textarea v-model="form.topicDesc"/>
      </a-form-model-item>
      <a-form-model-item label="状态" v-bind="formItemLayout">
        <a-radio-group v-model="form.status">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否推荐" v-bind="formItemLayout">
        <a-radio-group v-model="form.isTop">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否热门" v-bind="formItemLayout">
        <a-radio-group v-model="form.hotTop">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="排序号" v-bind="formItemLayout">
        <a-input-number
            v-model="form.sortNumber"
            allow-clear
            placeholder="输入排序号"
        />
      </a-form-model-item>
      <a-form-model-item ref="doorHeader" label="上传Logo" v-bind="formItemLayout">
        <div class="clearfix">
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              withCredentials
              :multiple="false"
              :data="{ bucketType: 'public', uploadType: 'image' }"
              :headers="headers"
              :file-list="imageList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="(info) => handleImageChange(info)"
          >
            <div v-if="imageList.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <!--  -->
          <a-modal
              v-drag-modal
              title="图片预览"
              :visible="previewVisible"
              :footer="null"
              @cancel="previewVisible = false"
          >
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {getBase64OfPreview} from '@/utils/common.js';

export default {
  name: "modifyModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 15},
      },
      title: "",
      mode: 'new',
      visible: false,
      confirmLoading: false,
      previewVisible: false,
      previewImage: "",
      imageList: [],
      form: {},
      rules: {
        topicTitle: [
          {
            required: true,
            message: "请输入话题名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
  },
  methods: {
    showModal(record, mode) {
      const that = this;
      let array = [];

      this.visible = true;
      this.mode = mode || 'new'


      if (this.mode == 'new') {
        this.title = "创建新话题";
        this.form = {
          status: 1,
          isTop: 0,
        };
      } else {
        this.title = "修改话题：" + record.topicTitle;
        this.form = {
          ...record,
        };

        let imageList = [record.logo];

        imageList.forEach((v) => {
          if (!v) return;
          let path = that.$helper.getFullImgPath(v);
          array.push({
            type: "image/png",
            name: v,
            uid: v,
            status: "done",
            thumbUrl: path,
            response: {data: path, code: 0},
          });
        });
      }
      this.imageList = array;
    },

    handleImageChange(info) {
      this.imageList = info.fileList || [];
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    beforeUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("只能支持JPEG/PNG/GIF图片!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 3;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过3MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview && !file.thumbUrl) {
        file.preview = await getBase64OfPreview(file.originFileObj);
      }
      this.previewImage = file.url || file.preview || file.thumbUrl;
      this.previewVisible = true;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.imageList.length<1){
            this.$message.warning("请选择图片");
            return
          }
          this.confirmLoading = true;
          this.form.logo = this.imageList.map((item) => {
            return item.response.data;
          })[0];
          this.$postJson("topic/update", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("修改成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
